@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.stream-links-container {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;
    
    flex-direction: row;    
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .link-separator {
        width: 2px;
        height: 32px;
        background-color: $grey-200;
    }
}