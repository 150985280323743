@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.show-card {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;

    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;

    width: 360px;

    margin-bottom: 56px;

    .show-image {
        width: 100%;
        height: 360px;

        margin-bottom: 16px;

        background-color: $grey-200;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .show-information {
        display: flex;
        display: -webkit-flex;
        flex: 0 0 auto;

        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;

        .show-content-information {

            .show-title {
                margin-bottom: 12px;

                @include openSansFont(28px, 700, 36px);
                color: $white;
                letter-spacing: 0.5px;
            }

            .show-author {
                margin-bottom: 12px;

                @include interFont(14px, 400, 24px);
                color: $white;
                letter-spacing: 0.25px;

                text-transform: uppercase;
            }

            .show-excerpt {
                @include interFont(16px, 400, 24px);
                color: $grey-900;
                letter-spacing: 0.5px;
            }
        }
    }

    &:hover {
        & > .show-information > .show-content-information > .show-title {
            color: $yellow-1000;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }

    &:active {
        & > .show-information > .show-content-information > .show-title {
            color: $yellow-600;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
}

@media (max-width: 1279px) {
    .show-card {
        width: 320px;

        margin-bottom: 40px;

        .show-image {
            height: 320px;
        }
    }
}

@media (max-width: 1023px) {
    .show-card {
        width: 234px;

        margin-bottom: 40px;

        .show-image {
            height: 234px;
        }

        .show-information {
            .show-content-information {
                .show-title,
                .show-author {
                    margin-bottom: 8px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .show-card {
        width: 288px;

        .show-image {
            height: 288px;
        }

        .show-information {
            .show-content-information {
                .show-title {
                    @include openSansFont(24px, 600, 32px);
                }

                .show-author {
                    @include interFont(12px, 400, 20px);
                }
            }
        }
    }
}

@media (max-width: 319px) {
    .show-card {
        width: 100%;

        .show-image {
            height: 160px;
        }
    }
}