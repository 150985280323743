@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.about-container {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;

    width: calc(100% - 122px);
    height: calc(100vh - 102px);
    height: calc(100dvh - 102px);

    padding: 56px 0;

    overflow-x: hidden;
    overflow-y: overlay;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: $transparent;
        border: 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $grey-100;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }

    .about {
        display: flex;
        display: -webkit-flex;
        flex: 0 0 auto;

        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: stretch;
        align-items: flex-start;

        width: 100%;
        max-width: 1160px;

        padding: 0 16px;
        box-sizing: border-box;

        .information,
        .image {
            width: 50%;
        }

        .information {
            display: flex;
            display: -webkit-flex;
            flex: 0 0 auto;

            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: center;
            align-items: flex-start;

            .about-title {
                display: flex;
                display: -webkit-flex;
                flex: 0 0 auto;

                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-content: center;
                align-items: center;

                width: 100%;
                height: 48px;
                margin-bottom: 40px;

                @include interFont(36px, 400, 36px);
                color: $white;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }

            .about-text {
                margin-bottom: 40px;

                h1,
                h2,
                h3,
                h4,
                h5 {
                    width: 100%;
                    margin: 20px 0;

                    @include openSansFont(36px, 600, 44px);
                    letter-spacing: 0.5px;
                }

                p {
                    width: 100%;
                    margin: 16px 0;

                    @include interFont(22px, 300, 36px);
                    letter-spacing: 0.5px;
                    color: $grey-900;

                    img {
                        width: 100%;
                        height: auto;

                        margin: 64px 0;
                    }

                    &:has(img) {
                        width: 100%;
                    }

                    strong {
                        font-weight: 600;
                    }
                }

                span {
                    display: flex;
                    display: -webkit-flex;
                    flex: 0 0 auto;

                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    width: 100%;
                }

                iframe {
                    width: 100%;
                    margin: 20px 0;
                }

                a {
                    color: $white;
                    text-decoration: underline;
                    text-underline-offset: 3px;

                    &:hover {
                        color: $yellow-1000;

                        text-decoration: underline;
                        text-underline-offset: 3px;
                        text-decoration-color: $yellow-1000;
                    }

                    &:active {
                        color: $yellow-600;

                        text-decoration: underline;
                        text-underline-offset: 3px;
                        text-decoration-color: $yellow-600;
                    }
                }

                ul,
                ol {
                    width: 100%;

                    box-sizing: border-box;
                    list-style-position: outside;
                    padding: 32px 32px 32px 0;

                    @include interFont(22px, 300, 36px);

                    color: $grey-900;

                    border: 2px solid $grey-200;

                    background-color: $grey-50;
                }

                li {
                    margin: 8px 0 8px 64px;

                    @include interFont(22px, 300, 36px);
                    letter-spacing: 0.5px;
                    color: $white;
                }

                img+em {
                    display: block;
                    font-size: 14px;
                    margin-bottom: 17px;
                }

                hr {
                    width: 100%;
                    height: 2px;

                    margin: 16px 0;

                    color: $grey-200;
                    background-color: $grey-200;
                    font-size: 0;
                    border: 0;
                }

                br {
                    display: none;
                }
            }

            .social-buttons {
                margin-bottom: 40px;

                .social-links-container>.social-button {
                    margin: 0 16px 16px 0;

                    .button {
                        padding: 0;
                    }
                }
            }
        }

        .image {
            display: flex;
            display: -webkit-flex;
            flex: 0 0 auto;

            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-content: center;
            align-items: center;

            img {
                width: 85%;
                height: auto;
            }
        }
    }
}

@media (max-width: 1279px) {
    .about-container {
        width: 100%;
        height: calc(100vh - 148px);
        height: calc(100dvh - 148px);

        padding: 32px 0;

        .about {
            justify-content: space-between;

            .information {
                width: 480px;

                margin: 0;

                .about-title {
                    margin-bottom: 32px;
                }

                .social-buttons {
                    margin-bottom: 32px;
                }

                .show-description {
                    margin-bottom: 0;
                }
            }

            .image {
                width: 480px;
                height: auto;

                img {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .about-container {
        width: 100%;
        height: calc(100vh - 148px);
        height: calc(100dvh - 148px);

        padding: 24px 0;

        .about {
            flex-direction: column-reverse;
            justify-content: space-between;

            max-width: 768px;

            .information {
                width: 100%;

                margin: 0;

                .about-title {
                    margin-bottom: 24px;

                    h1,
                    h2,
                    h3,
                    h4,
                    h5 {
                        width: 744px;

                        margin: 8px 0;
                    }

                    p {
                        width: 744px;
                        margin: 16px 0;

                        img {
                            width: 744px;
                            height: auto;

                            margin: 32px 0;
                        }

                        &:has(img) {
                            width: 744px;
                        }
                    }

                    span {
                        width: 744px;
                    }

                    iframe {
                        width: 100%;

                        margin: 16px 0;
                    }

                    ul,
                    ol {
                        width: 744px;

                        padding: 24px 32px 24px 0;
                    }

                    li {
                        margin: 8px 0 8px 64px;
                    }

                    hr {
                        margin: 8px 0;
                    }
                }

                .about-text {
                    margin-bottom: 48px;
                }

                .show-description {
                    margin-bottom: 0;
                }
            }

            .image {
                width: 100%;
                height: auto;

                margin-bottom: 48px;

                img {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .about-container {
        width: 100%;

        .about {
            width: 100%;

            .information {
                width: 100%;

                .about-title {
                    @include interFont(28px, 400, 28px);
                }

                .about-text {

                    h1,
                    h2,
                    h3,
                    h4,
                    h5 {
                        width: 100%;

                        @include openSansFont(24px, 600, 32px);
                    }

                    p {
                        width: 100%;

                        @include interFont(16px, 400, 24px);

                        img {
                            width: 100%;
                        }

                        &:has(img) {
                            width: 100%;
                        }

                        br {
                            height: 8px;
                        }
                    }

                    span {
                        width: 100%;
                    }

                    iframe {
                        width: 100% !important;
                    }

                    ul,
                    ol {
                        width: 100%;
                    }
                }
            }
        }
    }
}