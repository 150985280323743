// Primary colors:

$white: #ffffff;
$grey-900: #e5e5e5;
$grey-800: #cccccc;
$grey-700: #b2b2b2;
$grey-600: #999999;
$grey-500: #808080;
$grey-400: #666666;
$grey-300: #4c4c4c;
$grey-200: #333333;
$grey-100: #191919;
$grey-50: #0d0d0d;
$black: #000000;

// Secondary colors:

$yellow-1000: #ffde03;
$yellow-800: #ccb202;
$yellow-600: #998502;
$yellow-400: #665901;
$yellow-200: #332c01;

$red-1100: #1f0809;
$red-1000: #661a1f;
$red-800: #cc353e;
$red-600: #ff424d;
$red-400: #ff8e94;
$red-200: #ffd9db;

// Service colors

$transparent: #00000000;
$tap-color: #ffffff0a;
$overlay-color: #00000044;