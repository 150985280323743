html,
body {
    height: 100vh;
    height: 100dvh;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans+Condensed:wght@300;400;500;600;700;800&display=swap');

body {
    margin: 0;
    background: #000000;
    font-family: 'Inter', 'Open Sans Condensed', BlinkMacSystemFont, 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;

    overflow: hidden;

    -webkit-tap-highlight-color: #ffdd030a;
}

@media (pointer: none), (pointer: coarse) {
    html,
    body {
        width: 100%;
        height: 100vh;
        height: 100dvh;

        position: fixed;
    }
}

h1,
h2,
h3,
h5,
h6,
p,
code,
hr {
    color: white;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
}

table,
thead,
th,
tr,
td {
    color: white;
    border: 1px solid white;
}

button {
    border: 0;
    padding: 0;
    padding: 0;
    outline: none;
    background: none;
    color: inherit;
    font: inherit;
    overflow: visible;
    line-height: normal;
    box-sizing: content-box;

    cursor: pointer;
}

a {
    text-decoration: none;
    font: inherit;
    color: inherit;
}

::-moz-selection {
    color: black;
    background: white;
}

::selection {
    color: black;
    background: white;
}

video::-webkit-media-controls-timeline {
    display: none;
}

.chatbro_container * {
    font-family: 'Inter', BlinkMacSystemFont, 'Helvetica Neue' !important;
}