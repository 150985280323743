.not-found-container {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    box-sizing: border-box;

    width: calc(100vw - 122px);
    height: calc(100vh - 102px);
    height: calc(100dvh - 102px);

    padding: 40px 40px 101px 40px;

    h1 {
        font-size: 220px;
        font-weight: bold;
    }

    h3 {
        font-size: 20px;
        font-weight: 400;
    }
}

main.mobile .not-found-container {
    width: 100%;

    h1 {
        font-size: 150px;
    }

    h3 {
        width: 100%;
        text-align: center;
    }
}
