@import 'styles/colors.scss';
@import 'styles/fonts.scss';

header.header-container {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;

    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-between;

    height: 100px;
    width: 100%;

    align-items: center;
    border-bottom: 2px $grey-200 solid;

    .logo-container {
        display: flex;
        display: -webkit-flex;
        flex: 0 0 auto;

        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        width: 120px;

        cursor: pointer;

        color: $white;

        &:hover {
            color: $yellow-1000;
        }

        &:active {
            color: $yellow-600;
        }
    }

    .player-container {
        display: flex;
        display: -webkit-flex;
        flex: 0 0 auto;

        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;

        width: 1160px;

        padding: 0 16px;
        box-sizing: border-box;
    }

    .donate-button-container {
        display: flex;
        display: -webkit-flex;
        flex: 0 0 auto;

        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: center;
        align-items: center;

        width: 168px;
    }
}

header.header-container.mobile {
    display: none;
}

@media (max-width: 1279px) {
    header.header-container.desktop {
        display: none;
    }

    header.header-container.mobile {
        display: flex;

        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;

        height: 64px;

        box-sizing: border-box;
        padding: 8px 12px;

        background-color: $black;

        overflow: visible;

        &.bring-forward {
            z-index: 10003;
        }

        .logo-container {
            width: 188px;
            height: 48px;

            .logo {
                height: 48px;
            }
        }
    }
}

@media (max-width: 374px) {
    header.header-container.mobile {
        .header-menu-button {
            .button {
                .button-icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .logo-container {
            width: 160px;
            height: 41px;

            .logo {
                height: 41px;
            }
        }

        .donate-button {
            .button {
                .button-icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}