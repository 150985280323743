@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.project-card-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    width: 570px;
    height: fit-content;

    margin-bottom: 80px;

    color: $white;
    cursor: pointer;
}

.mobile .project-card-container {
    width: 100%;
    height: auto;

    margin-bottom: 40px;

    cursor: default;
}

.media-container {
    width: 100%;
    height: 320px;

    margin-bottom: 20px;

    overflow: hidden;
}

.mobile .media-container {
    height: calc((100vw / 3) * 2);
    width: 100%;
}

.media-image {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    position: relative;
    top: 0;
    left: 0;

    z-index: 2;

    width: 100%;
    height: 320px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    transition: height 0ms 400ms, opacity 400ms 0ms;
}

.media-image .circle {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    width: 64px;
    height: 64px;

    border: 1px solid $white;
    border-radius: 50%;
    background-color: #00000032;
    backdrop-filter: blur(5px) opacity(0.8);
    transition: backdrop-filter 100ms;
}

.media-image:hover .circle {
    background-color: #00000072;
    backdrop-filter: blur(5px) opacity(1);
}

.media-image .circle svg {
    margin-left: 6px;
}

.media-image.hidden {
    height: 0;
    opacity: 0;
}

.media-image.visible {
    height: 320px;
    opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
}

.mobile .media-image.visible {
    height: 100%;
}

.media-video {
    width: 100%;
    height: 100%;
}

.media-video video::-webkit-media-controls-timeline {
    display: block;
}

.project-information-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;

    width: 100%;
    height: auto;
}

.project-title {
    margin-bottom: 8px;
    
    @include openSansFont(28px, 700, 36px);
    letter-spacing: 0.5px;
    color: $white;
}

.project-tags {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;

    color: $white;
    margin-bottom: 8px;
}

.project-description {
    @include interFont(16px, 400, 24px);
    letter-spacing: 0.5px;
    color: $white;
}

.tag {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
}

.tag-name {
    cursor: pointer;
    user-select: none;
    
    margin-right: 15px;
    margin-bottom: -1px;
    
    @include interFont(14px, 500, 20px);
    letter-spacing: 1.25px;
    text-transform: uppercase;
    text-underline-offset: 3px;
}

.tag-name:hover {
    text-decoration: underline;
}

.tag-separator {
    margin: 0 10px;
    user-select: none;
}

.tag-separator.hidden {
    display: none;
}
